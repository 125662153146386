<template>
  <v-container>
    <v-row>
      <v-col sm="6" class="mx-auto">
        <v-card 
          elevation="4" 
          class="mx-auto" 
          justify="center">
          <v-card-title class="grey lighten-2">
            <span class="headlin">Contact Me</span>
          </v-card-title>

          <v-list>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Essex, UK</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-web</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                <a href="https://twitter.com/tfeltwell">Twitter - @tfeltwell</a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-web</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                <a href="https://www.linkedin.com/in/tom-feltwell/">LinkedIn</a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          
        </v-list>



          
          <!-- <v-card-text>Twitter</v-card-text>
          <v-card-text>LinkedIn</v-card-text>
          <v-card-text>Email</v-card-text>
          <v-card-actions></v-card-actions> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Contact",

}
</script>

<style>

</style>
